var swiper__headerHome = new Swiper('.swiper__headerHome', {
    speed: 400,
    spaceBetween: 0,
    slidesPerView: 1,
    loop: true,
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
});